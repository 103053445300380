import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@cplace-next/storybook-shell-lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import sha1 from 'sha1';

@UntilDestroy()
@Component({
  selector: 'cf-storybook-shell-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public token = new UntypedFormControl('', [Validators.required]);

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  public getErrorMessage() {
    if (this.token.hasError('required')) {
      return 'Access token must be provided';
    } else if (this.token.hasError('incorrect')) {
      return 'Wrong access token!';
    } else {
      return '';
    }
  }

  public authenticate(): void {
    const token = sha1(this.token.value) as string;
    this.authService
      .authenticate(token)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          window.localStorage.setItem(AuthService.LC_KEY, token);
          window.location.href = this.route.snapshot.queryParams['redirectTo'] || '/';
        },
        () => {
          this.token.setErrors({ incorrect: true });
        }
      );
  }
}
