import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public static readonly LC_KEY = 'STORYBOOK_ACCESS_TOKEN';
  constructor(private httpService: HttpClient) {}

  public authenticate(token?: string): Observable<boolean> {
    const newToken = token || window.localStorage.getItem(AuthService.LC_KEY);
    if (!newToken) {
      return throwError(`Token not found!`);
    }
    return this.httpService
      .get(`/${newToken}.txt`, {
        responseType: 'text',
      })
      .pipe(mapTo(true));
  }
}
