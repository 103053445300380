import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CfStorybookLibModule } from '@cplace-next/storybook-shell-lib';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [LoginComponent],
  exports: [LoginComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, CfStorybookLibModule, PasswordModule, ButtonModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class LoginModule {}
