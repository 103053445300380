<form class="login-dialog">
  <div>
    <h1>Login</h1>
    <h3>To proceed please provide the access token</h3>
    <h4>
      The access token can be found
      <a href="https://base.cplace.io/pages/ls7n5f4jv457iub9l6lql60yy/Access-Token-for-cplace-Knowledge-Base" target="_blank">here</a>
    </h4>
  </div>
  <div class="actions">
    <p-password [formControl]="token" aria-describedby="token-help" [feedback]="false"></p-password>
    <button pButton (click)="authenticate()">Click</button>
    <small *ngIf="token.invalid" id="token-help" class="p-error">{{ getErrorMessage() }}</small>
  </div>
</form>
