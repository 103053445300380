import { Component } from '@angular/core';
import manifest from '../../manifest.json';

@Component({
  selector: 'cf-storybook-shell-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public apps: string[] = manifest.storybook.apps || [];
}
